import styled, {
    createGlobalStyle,
    css,
  } from "styled-components"

const normalize = css`
  @-ms-viewport {
    width: device-width;
  }

  /**
 * general reset
 */

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video,
  main {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /**
 * HTML5 display-role reset for older browsers
 */

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section,
  main,
  summary {
    display: block;
  }

  /**
 * inherit box model for all elements
 */

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    /* 1 */
    box-sizing: border-box;
    /* 2 */
    touch-action: manipulation;
    /* 3 */
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    /* 4 */
    -ms-overflow-style: scrollbar;
    /* 5 */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    scroll-behavior: smooth;
  }

  body {
    /* 1 */
    line-height: 1;
    /* 2 */
    font-family: sans-serif;
    /* 3 */
    text-align: left;
  }

  /**
 * Lists
 */

  ol,
  ul {
    list-style: none;
  }

  /**
 * Quotes
 */

  blockquote,
  q {
    quotes: none;
  }

  blockquote::before,
  blockquote::after,
  q::before,
  q::after {
    content: "";
    content: none;
  }

  /**
 * Tables
 */

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  caption {
    caption-side: bottom;
  }

  th {
    /* 1 */
    text-align: inherit;
  }

  /**
 * Horizontal Lines
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

  hr {
    /* 1 */
    box-sizing: content-box;
    height: 0;
    /* 2 */
    overflow: visible;
  }

  /**
 * Preformatted Text
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Don't allow content to break outside
 * 3. We have @viewport set which causes scrollbars to overlap content in IE11
 *    and Edge, so we force a non-overlapping, non-auto-hiding scrollbar to
 *    counteract.
 */

  pre,
  code,
  kbd,
  samp {
    /* 1 */
    font-family: monospace, monospace;
  }

  pre {
    /* 2 */
    overflow: auto;
    /* 3 */
    -ms-overflow-style: scrollbar;
  }

  /**
 * Links
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */

  a {
    /* 1 */
    background-color: transparent;
    /* 2 */
    -webkit-text-decoration-skip: objects;
  }

  /**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 * 3. Add explicit cursor to indicate changed behavior.
 * 4. Prevent the text-decoration to be skipped.
 */

  abbr[title] {
    /* 1 */
    border-bottom: none;
    /* 2 */
    text-decoration: underline;
    text-decoration: underline dotted;
    /* 3 */
    cursor: help;
    /* 4 */
    text-decoration-skip-ink: none;
  }

  /**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

  b,
  strong {
    font-weight: bolder;
  }

  /**
 * Add the correct font size in all browsers.
 */

  small {
    font-size: 80%;
  }

  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  /**
 * Remove the border on images inside links in IE 10.
 */

  img {
    border-style: none;
  }

  /**
 * Hide SVG overflow in IE
 */

  svg:not(:root) {
    overflow: hidden;
  }

  button {
    border-radius: 0;
  }

  button:focus {
    outline: none;
  }

  /**
 * form element resets
 * 1. Remove the margin in Firefox and Safari
 * 2. inherit font rules
 */

  input,
  button,
  select,
  optgroup,
  textarea {
    /* 1 */
    margin: 0;
    /* 2 */
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button,
[type="reset"],
[type="submit"],
/* 1 */
[type="button"] {
    /* 2 */
    -webkit-appearance: button;
  }

  /**
 * Remove the default appearance of temporal inputs to avoid a Mobile Safari
 * bug where setting a custom line-height prevents text from being vertically
 * centered within the input.
 * See https://bugs.webkit.org/show_bug.cgi?id=139848
 * and https://github.com/twbs/bootstrap/issues/11266
 */

  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    -webkit-appearance: listbox;
  }

  /**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Textareas should really only resize vertically so they don't break their
 *    (horizontal) containers.
 */

  textarea {
    overflow: auto;
    resize: vertical;
  }

  /**
 * Show the overflow in IE.
 */

  button,
  input {
    overflow: visible;
  }

  /**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 */

  button,
  select {
    text-transform: none;
  }

  /**
 * Remove the inheritance of word-wrap in Safari.
 * See https://github.com/twbs/bootstrap/issues/24990
 */
  select {
    word-wrap: normal;
  }

  /**
 * Remove inner border and padding from Firefox, but don't restore the outline
 * like Normalize.
 */

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  /**
 * 1. Add the correct box sizing in IE 10-
 * 2. Remove the padding in IE 10-
 */
  input[type="radio"],
  input[type="checkbox"] {
    /* 1 */
    box-sizing: border-box;
    /* 2 */
    padding: 0;
  }

  /**
 * Suppress the focus outline on elements that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 * Credit: https://github.com/suitcss/base
 */

  [tabindex="-1"]:focus {
    outline: 0 !important;
  }

  fieldset {
    min-width: 0;
  }
  legend {
    /* 1 */
    max-width: 100%;
    white-space: normal;
    /* 2 */
    color: inherit;
    /* 3 */
    display: block;
  }

  progress {
    vertical-align: baseline;
  }

  textarea {
    overflow: auto;
  }

  [type="checkbox"],
  [type="radio"] {
    /* 1 */
    box-sizing: border-box;
    /* 2 */
    padding: 0;
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    /* 1 */
    -webkit-appearance: textfield;
    /* 2 */
    outline-offset: -2px;
  }

  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    /* 1 */
    -webkit-appearance: button;
    /* 2 */
    font: inherit;
  }

  output {
    display: inline-block;
  }

  template {
    display: none;
  }

  [hidden] {
    display: none;
  }

  body {
    font-family: Montserrat;
  }

  body::-webkit-scrollbar {
    width: 0.75em;
  }

  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  @media only screen and (max-width: 320px) {
    html {
      font-size: 7px;
    }
  }

  @media only screen and (min-width: 321px) {
    html {
      font-size: 8px;
    }
  }

  @media only screen and (min-width: 576px) {
    html {
      font-size: 10px;
    }
  }

  @media only screen and (min-width: 1900px) {
    html {
      font-size: 12px;
    }
  }

  @media only screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 1.25) {
    html {
      font-size: 11px;
    }
  }
`

const GlobalStyle = createGlobalStyle(normalize)

const LayoutWrapper = styled.div`
  position: relative;
  min-height: 100vh;
`
const ContentWrapper = styled.div`
  padding-bottom: ${({
    theme: {
      footer: { mobile },
    },
  }) => mobile};

  @media only screen and (min-width: 576px) {
    padding-bottom: ${({
      theme: {
        footer: { desktop },
      },
    }) => desktop};
  }
`

export {
    GlobalStyle,
    LayoutWrapper,
    ContentWrapper
}