import React from "react"
import { ThemeProvider } from "styled-components"
import Header from "../header"
import Footer from "../footers/footer"
import { theme } from "../../config"
import { GlobalStyle, LayoutWrapper, ContentWrapper } from "./style"
import Cookies from "../cookies"
import SEO from '../seo'

const Layout = ({
  children,
  footer: FinalFooter = Footer,
  contentWrapper: FinalContentWrapper = ContentWrapper,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <LayoutWrapper>
        <SEO />
        <GlobalStyle />
        <Header />
        <FinalContentWrapper>{children}</FinalContentWrapper>
        <FinalFooter />
        <Cookies />
      </LayoutWrapper>
    </ThemeProvider>
  )
}

export default Layout
