const theme = {
  palette: {
    pink: "#de007d",
    darkblue: "#071e2b",
    darkblueRGBA: "rgba(7, 30, 43, 0.9)",
    white: "#FFF",
    black: "#000"
  },
  font: {
    primary: "Montserrat",
    sizes: {
      s50: "3.125rem",
      s28: "1.75rem",
      s22: "1.375rem",
      s40: "2.5rem",
      s36: "2.25rem",
      s24: "1.5rem",
      s19: "1.1875rem",
      s26: "1.625rem",
      s120: "7.5rem",
      s21: "1.3125rem",
      s108: "6.75rem",
      s42: "2.625rem",
      s31: "1.9375rem",
      s44: "2.75rem",
      s30: "1.875rem",
      s80: "5rem",
      s60: "3.75rem",
      s52: "3.25rem",
      s62: "3.875rem",
    },
    weights: {
      w100: "100",
      w200: "200",
      w300: "300",
      w400: "400",
      w500: "500",
      w600: "600",
      w700: "700",
    },
  },
  spacing: {
    s5: "0.3125rem",
    s10: "0.625rem",
    s15: "0.9375rem",
    s20: "1.25rem",
    s25: "1.5625rem",
    s30: "1.875rem",
    s35: "2.1875rem",
    s40: "2.5rem",
    s45: "2.8125rem",
    s50: "3.125rem",
  },
  header: {
    mobile: "10rem",
    desktop: "12rem",
  },
  footer: {
    mobile: "31rem",
    desktop: "14rem",
  },
  scroller: {
    mobile: "9rem",
    desktop: "11.5625rem",
  },
  content: {
    padding: {
      mobile: "2.5rem",
      desktop: "11.75rem",
      tablet: "7rem",
    },
  },
}

export { theme }
