import CookieConsent from "react-cookie-consent"
import React from "react"
import { Link as GLink } from "gatsby"
import styled from 'styled-components'
import './index.css'

const Link = styled(GLink)`
  color: white;
`

const Cookies = () => (
  <CookieConsent
    location="bottom"
    buttonText="Accept"
    declineButtonText="Decline"
    cookieName="gatsby-gdpr-google-analytics"
    contentClasses="cookie-consent-content"
  >
    We use a number of different cookies on our website, including strictly
    necessary, performance, advertising, and social media or content cookies.
    Cookies make your browsing experience better by allowing the website to
    remember your actions and preferences (such as login and region selection).
    This means you do not have to re-enter this information each time you return
    to the site or browse from one page to another. Cookies also provide
    information on how people use the website, for instance whether it is their
    first time visiting or if they are a frequent visitor. Read more about
    cookies (and other similar tracking technologies) and how we use the data
    collected through these technologies, in the{" "}
    <Link to="/privacy-policy"> Michael Allen & Partners Privacy Policy.</Link>
  </CookieConsent>
)

export default Cookies
