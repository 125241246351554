import styled from "styled-components"


const HeaderWrapper = styled.div`
  padding: 0 ${({ theme: { spacing } }) => spacing.s40};
  padding-top: ${({ theme: { spacing } }) => spacing.s40};
  background-color: ${({
    theme: {
      palette: { darkblueRGBA },
    },
  }) => darkblueRGBA};
  height: ${({
    theme: {
      header: { mobile },
    },
  }) => mobile};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 12;
  top: 0;
  left: 0;
  right: 0;

  @media only screen and (min-width: 576px) {
    padding: 0 ${({ theme: { spacing } }) => "7rem"};
    padding-top: 45px;
    height: ${({
      theme: {
        header: { desktop },
      },
    }) => desktop};
  }
`

const HeaderItem = styled.div`
  height: 100%;

  & a:link,
  & a:visited {
    color: white;
    text-decoration: none;
  }
`

const Navigation = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Menu = styled.ul`
  flex: 1;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s;
`
const NavigationItem = styled.div`
  position: relative;
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme: { spacing } }) => spacing.s20};

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    top: -65px;
    left: 0;
    opacity: 0;
    height: calc(100% + 65px);
    z-index: 1;
    transition: all 0.3s;
  }

  &:hover:before {
    background-color: #de007d;
    opacity: 1;
  }

  &:hover ${Menu} {
    opacity: 1;
  }
`

const MenuItem = styled.li`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s19};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w300};
  padding: 0.4rem 0;

  & a:link,
  & a:visited {
    color: white;
    text-decoration: none;
  }
`

const Title = styled.div`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s22};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w500};
  margin-bottom: ${({ theme: { spacing } }) => spacing.s20};
  z-index: 2;
  cursor: default;
`

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: white;
  cursor: pointer;
`

const LogoImage = styled.img`
  max-width: 6rem;
  margin-right: ${({ theme: { spacing } }) => spacing.s10};

  @media only screen and (min-width: 576px) {
    margin-top: -${({ theme: { spacing } }) => spacing.s10};
    margin-right: ${({ theme: { spacing } }) => spacing.s30};
  }
`

const LogoText = styled.div`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s28};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w700};
  position: relative;
  left: -6.625rem;
  transition: left 0.5s ease-in;

  @media only screen and (min-width: 576px) {
    left: -7.875rem;
  }
`

const Contact = styled.div`
  width: 2.3rem;
  height: 2.3rem;
  background-image: url(${({ src }) => src});
  background-size: cover;
  cursor: pointer;
  margin-right: ${({ theme: { spacing } }) => spacing.s10};
`

const Search = styled.div`
  width: 2.3rem;
  height: 2.3rem;
  background-image: url(/search.png);
  cursor: pointer;
  background-size: cover;
`

const Extra = styled.div`
  margin-left: 6.25rem;
  margin-top: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Mobile = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-width: 769px) {
    & {
      display: none;
    }
  }
`

const Desktop = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 768px) {
    & {
      display: none;
    }
  }
`

const Backdrop = styled.div`
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  position: fixed;
  top: ${({ theme: { header } }) => header.mobile};
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;

  @media only screen and (min-width: 576px) {
    top: ${({ theme: { header } }) => header.desktop};
  }
`

const Drawer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: ${({ theme: { header } }) => header.mobile};
  bottom: 0;
  width: 250px;
  right: 0;
  z-index: 1;
  background-color: ${({
    theme: {
      palette: { darkblue },
    },
  }) => darkblue};
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(100%)")};
  transition: all 0.3s cubic-bezier(0.694, 0.0482, 0.335, 1);
  border-top: 1px solid #ffffff14;

  @media only screen and (min-width: 321px) {
    width: 300px;
  }

  @media only screen and (min-width: 576px) {
    top: ${({ theme: { header } }) => header.desktop};
  }

  @media only screen and (min-width: 769px) {
    display: none;
  }
`

const MobileNav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({
    theme: {
      palette: { pink },
    },
  }) => pink};
  flex: 1;
`

const MobileNavItem = styled.div`
  margin-bottom: ${({ theme: { spacing } }) => spacing.s40};
  & a:link,
  & a:visited {
    color: white;
    text-decoration: none;
  }
`

const MobileNavItemTitle = styled.div`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s50};
  text-align: center;
  padding: ${({ theme: { spacing } }) => spacing.s15};
`

const MobileNavList = styled.ul`
  display: block;
`

const MobileNavListItem = styled.li`
  padding: ${({
    theme: {
      spacing: { s5 },
    },
  }) => s5};
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s40};
  text-align: center;
`

export {
    MobileNavListItem,
    MobileNavList,
    MobileNavItemTitle,
    MobileNavItem,
    MobileNav,
    Drawer,
    Backdrop,
    Desktop,
    Mobile,
    Extra,
    Search,
    Contact,
    LogoText,
    Logo,
    Title,
    LogoImage,
    MenuItem,
    Menu,
    HeaderWrapper,
    NavigationItem,
    Navigation,
    HeaderItem
}