import React, { useState } from "react"
import { Link } from "gatsby"
import { HamburgerElastic } from "react-animated-burgers"
import {
  MobileNavListItem,
  MobileNavList,
  MobileNavItemTitle,
  MobileNavItem,
  MobileNav,
  Drawer,
  Backdrop,
  Desktop,
  Mobile,
  Extra,
  Search,
  Contact,
  LogoText,
  Logo,
  Title,
  LogoImage,
  MenuItem,
  Menu,
  HeaderWrapper,
  NavigationItem,
  Navigation,
  HeaderItem,
} from "./style"

const DesktopMenuComponent = () => {
  return (
    <Desktop>
      <HeaderItem>
        <Navigation>
          <NavigationItem>
            <Title>Who we are</Title>
            <Menu>
              <MenuItem>
                <Link to="/about">About us</Link>
              </MenuItem>
            </Menu>
          </NavigationItem>
          <NavigationItem>
            <Title>What we do</Title>
            <Menu>
              <MenuItem>
                <Link to="/services">Our services</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/clients">Our Experience</Link>
              </MenuItem>
            </Menu>
          </NavigationItem>
          <NavigationItem>
            <Title>What we think</Title>
            <Menu>
              <MenuItem>
                <Link to="/insights">Insights</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/latest-publication">Order Publications</Link>
              </MenuItem>
            </Menu>
          </NavigationItem>
        </Navigation>
      </HeaderItem>
      <HeaderItem>
        <Extra>
          <Link to="/contact" activeClassName="contact-active">
            <Contact src="/contact.png" />
          </Link>
          <Search />
        </Extra>
      </HeaderItem>
    </Desktop>
  )
}

const MobileMenuComponent = () => {
  const [isOpen, toggleOpen] = useState(false)

  return (
    <Mobile>
      <HeaderItem>
        <HamburgerElastic
          buttonStyle={{ padding: 0 }}
          isActive={isOpen}
          buttonColor="transparent"
          barColor="white"
          buttonWidth={30}
          onClick={() => toggleOpen(!isOpen)}
        />
      </HeaderItem>
      <Drawer isOpen={isOpen}>
        <MobileNav>
          <MobileNavItem>
            <MobileNavItemTitle>Who we are</MobileNavItemTitle>
            <MobileNavList>
              <MobileNavListItem>
                <Link to="/about">About us</Link>
              </MobileNavListItem>
            </MobileNavList>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavItemTitle>What we do</MobileNavItemTitle>
            <MobileNavList>
              <MobileNavListItem>
                <Link to="/services">Our services</Link>
              </MobileNavListItem>
              <MobileNavListItem>
                <Link to="/clients">Our Experience</Link>
              </MobileNavListItem>
            </MobileNavList>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavItemTitle>What we think</MobileNavItemTitle>
            <MobileNavList>
              <MobileNavListItem>
                <Link to="/insights">Insights</Link>
              </MobileNavListItem>
              <MobileNavListItem>
                <Link to="/latest-publication">Trends</Link>
              </MobileNavListItem>
            </MobileNavList>
          </MobileNavItem>
        </MobileNav>
      </Drawer>
      <Backdrop isOpen={isOpen} onClick={() => toggleOpen(false)} />
    </Mobile>
  )
}

class Header extends React.Component {
  componentDidMount() {
      const logo = document.getElementById("logo")
      const logoText = document.getElementById("logoText")

      setTimeout(() => {
        logo.classList.add("logo-appear")
        logoText.classList.add("logo-text-appear")
      }, 1500)
    }


  render() {
    const { location } = this.props

    return (
      <HeaderWrapper>
        <HeaderItem>
          <a href="/">
            <Logo>
              <LogoImage src="/logo.png" id="logo" />
              <LogoText id="logoText">MICHAEL ALLEN & PARTNERS</LogoText>
            </Logo>
          </a>
        </HeaderItem>
        <MobileMenuComponent />
        <DesktopMenuComponent location={location} />
      </HeaderWrapper>
    )
  }
}

export default Header
