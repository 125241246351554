import React from "react"
import styled from "styled-components"
import { Row, Col, Container } from "styled-bootstrap-grid"
import { Link } from "gatsby"

const FooterWrapper = styled.div`
  background-color: ${({
    theme: {
      palette: { darkblue },
    },
  }) => darkblue};
  position: absolute;
  bottom: 1px;
  padding: 2rem;
  width: 100%;
  height: ${({
    theme: {
      footer: { mobile },
    },
  }) => mobile};

  &:before {
    content: ${({ noSeparator }) => !noSeparator && "' '"};
    position: absolute;
    height: 1px;
    top: -20px;
    left: ${({ theme: { spacing } }) => spacing.s20};
    right: ${({ theme: { spacing } }) => spacing.s20};
    background-color: white;
  }

  & > div {
    height: 100%;
  }

  @media only screen and (min-width: 768px) {
    padding: 2rem ${({ theme: { spacing } }) => "7rem"};
    height: ${({
      theme: {
        footer: { desktop },
      },
    }) => desktop};
    &:before {
      left: ${({ theme: { spacing } }) => spacing.s40};
      right: ${({ theme: { spacing } }) => spacing.s40};
    }
  }
`

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: white;
  cursor: pointer;
`

const LogoImage = styled.img`
  max-width: 6rem;
  margin-right: ${({ theme: { spacing } }) => spacing.s10};
  position: relative;
  top: 2px;

  @media only screen and (min-width: 576px) {
    margin-right: ${({ theme: { spacing } }) => spacing.s30};
  }
`

const LogoText = styled.div`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s28};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w700};
`

const Address = styled.div`
  color: white;
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s24};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w500};
`

const City = styled.div`
  margin-bottom: ${({ theme: { spacing } }) => spacing.s15};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w500};
`

const MobileNumber = styled.div`
  line-height: 1.8;
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w500};
`

const Email = styled.div``

const FooterItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  & a:link,
  & a:visited {
    color: white;
    text-decoration: none;
  }

  @media only screen and (min-width: 768px) {
    justify-content: ${({ align }) => align || "flex-start"};
  }
`

const Social = styled.img`
  max-width: 35px;
  height: 25px;
  cursor: pointer;
  display: inline-block;

  &:first-child {
    margin-right: ${({ theme: { spacing } }) => spacing.s5};
    margin-bottom: 0;
  }

  @media only screen and (min-width: 768px) {
    display: block;

    &:first-child {
      margin-right: ${({ theme: { spacing } }) => spacing.s10};
      margin-bottom: ${({ theme: { spacing } }) => spacing.s10};
    }
  }
`

const SocialRow = styled.div`
  margin-right: ${({ theme: { spacing } }) => spacing.s5};

  @media only screen and (min-width: 768px) {
    margin-right: 0;
  }
`

const FooterContainer = styled(Container)`
  padding: ${({ theme: { spacing } }) => spacing.s15} 0;
`

const FooterRow = styled(Row)`
  margin: -${({ theme: { spacing } }) => spacing.s15} 0;
`

const FooterCol = styled(Col)`
  padding: ${({ theme: { spacing } }) => spacing.s15} 0;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s24};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w500};

  & > * {
    margin-bottom: 10px;
  }
`

const Footer = ({ noSeparator, ...props }) => {
  return (
    <FooterWrapper noSeparator={noSeparator} className="footer">
      <FooterContainer fluid>
        <FooterRow>
          <FooterCol md={4}>
            <FooterItem>
              <Link to="/">
                <Logo>
                  <LogoImage src="/logo2.png" />
                  <LogoText>MICHAEL ALLEN & PARTNERS</LogoText>
                </Logo>
              </Link>
            </FooterItem>
          </FooterCol>
          <FooterCol md={4}>
            <FooterItem>
              <Address>
                <City>London</City>
                <MobileNumber> +44 (0) 7768 012 364 </MobileNumber>
                <Email>info@michaelallenpartners.com</Email>
              </Address>
            </FooterItem>
          </FooterCol>
          <FooterCol md={3}>
            <FooterItem>
              <Links>
                <Link to="/privacy-policy">Privacy policy</Link>
                <Link to="/delivery-and-shipping-policy">Shipping & delivery policy</Link>
              </Links>
            </FooterItem>
          </FooterCol>
          <FooterCol md={1}>
            <FooterItem align="flex-end">
              <SocialRow>
                <Social src="/youtube.png" />
                <Social src="/instagram.png" />
              </SocialRow>
              <SocialRow>
                <Social src="/twitter.png" />
                <Social src="/linkedin.png" />
              </SocialRow>
            </FooterItem>
          </FooterCol>
        </FooterRow>
      </FooterContainer>
    </FooterWrapper>
  )
}

export default Footer
